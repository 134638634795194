import React, { useState } from "react"
import {
	Avatar,
	Badge,
	Fab,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Popover,
	Switch,
	TextField,
	Typography
} from "@material-ui/core"
import {
	ArrowForwardIos as ArrowForwardIosIcon,
	CameraAltOutlined as CameraIcon,
	Add as AddIcon
} from "@material-ui/icons"

import Dropzone from "react-dropzone"

import {
	Portlet,
	Divider,
	Loading,
	ActionDialog,
	Notification,
	InputDialog,
	ColorPickerInput
} from "@/components"

import UploadAvatarSkeleton from "@/skeletons/WidgetChat/UploadAvatar"
import ChatWhatsappSkeleton from "@/skeletons/WidgetChat/ChatWhatsapp"

import useStyles from "@/pages/WidgetChat/ChatWhatsapp/styles"
import useCustomStyles from "@/styles/custom"

import {
	WidgetDataType,
	WidgetTriggerRulesProtocol,
	UpdatedWidgetTriggerRulesProtocol,
	TriggerTypeProtocol
} from "@/Protocols/WidgetProtocol"
import {
	ChangeContactsDataType,
	ContactsDataDialogType,
	ContactsDataType
} from "@/Protocols/ContactProtocol"

import externalConfig from "@/config/external"
import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import { ObjectLiteralValuesProtocol, ItemSelectProtocol } from "@/Protocols/GeneralProtocol"
import { isMobile } from "@/utils/checkDevice"
import { livePreviewID } from "@/hooks/useLivePreview"
import { formatPhoneNumber } from "@/utils/mask"

type ChatWhatsappEditorProps = {
	widgetId: number
	customerSite?: string | null
	showAlreadyRegistered?: boolean
}

type ColorPropsType = {
	hex: string,
	rgb: {
		r: number,
		g: number,
		b: number,
		a: number,
	},
	hsl: {
		h: number,
		s: number,
		l: number,
		a: number,
	},
}

const widgetPositionOptions: ItemSelectProtocol[] = [
	{
		value: "left",
		label: "Esquerda"
	},
	{
		value: "middle",
		label: "Centro"
	},
	{
		value: "right",
		label: "Direita"
	}
]

const widgetHowOftenAppearOptions: ItemSelectProtocol[] = [
	{
		value: "onceADay",
		label: "Uma vez por dia"
	},
	{
		value: "onceAWeek",
		label: "Uma vez por semana"
	},
	{
		value: "every",
		label: "Sempre"
	}
]

const ChatWhatsappEditor: React.FC<ChatWhatsappEditorProps> = (props) => {
	const { widgetId, customerSite, showAlreadyRegistered } = props

	const [widgetActive, setWidgetActive] = useState<boolean>(false)

	const [loadingWidget, setLoadingWidget] = useState<boolean>(true)
	const [widgetData, setWidgetData] = useState<WidgetDataType>({} as WidgetDataType)
	const [newTriggerRulesData, setNewTriggerRulesData] = useState<WidgetTriggerRulesProtocol>({} as WidgetTriggerRulesProtocol)
	const [loadingPutTriggerRules, setLoadingPutTriggerRules] = useState<boolean>(false)

	const [loadingPutContact, setLoadingPutContact] = useState<boolean>(false)
	const [contactsData, setContactsData] = useState<Array<ContactsDataType>>([])
	const [loadingUploadAvatar, setLoadingUploadAvatar] = useState<boolean>(false)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [selectContact, setSelectContact] = useState<any>({
		contactData: {},
		open: false
	} as ContactsDataDialogType)

	const [isMobilePreviewActive, setIsMobilePreviewActive] = useState<boolean>(false)

	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
	const openColorPopover = Boolean(anchorEl)

	const classes = useStyles({ isMobilePreviewActive })
	const customClasses = useCustomStyles()

	const {
		validation,
		clearValidation,
		triggerValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const reloadIframe = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const iframe: any = document.getElementById(livePreviewID)

		// eslint-disable-next-line no-self-assign
		iframe.src = iframe.src
	}

	const handleToggleMobileLivePreview = () => {
		setIsMobilePreviewActive(!isMobilePreviewActive)
	}

	const getWidgetData = async () => {
		setLoadingWidget(true)

		try {
			const { data } = await ApiService.get(`/widget/${widgetId}`)

			const { widget, widgetConfigs, contacts } = data

			setWidgetActive(widget.status === "ACTIVE")
			setWidgetData({ widget, widgetConfigs })
			setContactsData(contacts)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setLoadingWidget(false)
	}

	const changeWidgetConfigData = async (value: number | string | boolean | object, type: string, key?: string) => {
		try {
			const { id } = widgetData.widget

			const newData = key
				? {
					[type]: {
						[key]: value || ""
					}
				} : {
					[type]: value || ""
				}

			const { data } = await ApiService.put(`/widget/${id}`, newData)
			const { widget, widgetConfigs } = data

			setWidgetData({ widget, widgetConfigs })
			reloadIframe()

			Notification.success({ message: "Informação alterada com sucesso!" })

			return true
		} catch (error) {
			triggerValidation(error)
			return false
		}
	}

	const handleContactInputChange = (changedData: ChangeContactsDataType) => {
		setSelectContact({
			contactData: {
				...selectContact.contactData,
				...changedData
			},
			open: true
		})
	}

	const openContactDialog = (data: ContactsDataType) => {
		clearValidation("name")
		clearValidation("contact")
		clearValidation("status_message")

		handleContactInputChange(data)
	}

	const closeContactDialog = () => {
		setSelectContact({
			contactData: {},
			open: false
		})
	}

	const handleAddContact = async () => {
		setSelectContact({
			contactData: {},
			open: true
		})
	}

	const saveNewContact = async () => {
		setLoadingPutContact(true)

		try {
			if (selectContact.contactData?.id) {
				const { id } = selectContact.contactData

				await ApiService.put(`/contact/${id}`, selectContact.contactData)

				Notification.success({
					message: "Contato alterado com sucesso!"
				})

				setContactsData([selectContact.contactData])
			} else {
				const { data } = await ApiService.post("/widget-contact/", {
					type: "whatsApp",
					contact: selectContact.contactData?.contact,
					name: selectContact.contactData?.name,
					additional_info: selectContact.contactData?.additional_info,
					widget_id: widgetId
				})

				Notification.success({
					message: "Contato cadastrado com sucesso!"
				})
				setContactsData([data])
			}
			reloadIframe()
			closeContactDialog()
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingPutContact(false)
	}

	const handleWidgetStatus = async () => {
		try {
			const { id } = widgetData.widget

			const status = !widgetActive ? "ACTIVE" : "INACTIVE"

			await ApiService.put(`/widget/widget-status/${id}`, { widget_status: status })

			Notification.success({
				message: "Ativação alterada com sucesso!"
			})

			reloadIframe()
			setWidgetActive(!widgetActive)
		} catch (error) {
			Notification.error({
				message: "Falhar ao alterar o status de ativação do widget"
			})
		}
	}

	const getWidgetPosition = () => {
		const { widget_position } = widgetData.widgetConfigs?.display_rules

		if (!widget_position) return "A posição aparecerá aqui"

		const _widgetPositionOptions: ObjectLiteralValuesProtocol = {
			right: "Direita",
			left: "Esquerda",
			middle: "Centro",
			default: ""
		}

		return _widgetPositionOptions[widget_position] ?? _widgetPositionOptions.default
	}

	const getWhenAppear = () => {
		const { trigger_activation_type, trigger_activation_baseMetric } = widgetData?.widgetConfigs?.trigger_rules

		if (!trigger_activation_type) {
			return "Seu método de ativação aparecerá aqui..."
		} else {
			const triggerTypeOptions: ObjectLiteralValuesProtocol = {
				timeInPage: "Tempo na página:",
				scrollInPage: "Rolagem na página:",
				immediately: "Imediatamente",
				never: "Somente quando clicar no ícone",
				default: ""
			}

			const triggerType = triggerTypeOptions[trigger_activation_type] ??
				triggerTypeOptions.default

			let triggerBaseMetric = ""
			if (
				trigger_activation_type !== "immediately" &&
				trigger_activation_type !== "never"
			) {
				triggerBaseMetric = trigger_activation_type === "timeInPage"
					? `${trigger_activation_baseMetric / 1000} segundos`
					: `${trigger_activation_baseMetric * 100} %`
			}
			return `${triggerType} ${triggerBaseMetric}`
		}
	}

	const handleTriggerRulesInputChange = (changedData: UpdatedWidgetTriggerRulesProtocol) => {
		setNewTriggerRulesData({
			...newTriggerRulesData,
			...changedData
		})
	}

	const closeTriggerRulesDialog = () => {
		setNewTriggerRulesData({} as WidgetTriggerRulesProtocol)
	}

	const saveNewTriggerRules = async () => {
		setLoadingPutTriggerRules(true)

		await changeWidgetConfigData(newTriggerRulesData, "trigger_rules")

		closeTriggerRulesDialog()

		setLoadingPutTriggerRules(false)
	}

	const openTriggerRulesDialog = () => {
		const { trigger_rules } = widgetData.widgetConfigs

		clearValidation("trigger_activation_type")
		clearValidation("trigger_activation_baseMetric")
		clearValidation("trigger_repeat")

		handleTriggerRulesInputChange(trigger_rules)
	}

	const getChangeWhenAppearOptions = () => {
		const { trigger_activation_type } = newTriggerRulesData

		const triggerActivationOptions: ItemSelectProtocol[] = [
			{
				value: "timeInPage",
				label: "Tempo na página"
			},
			{
				value: "scrollInPage",
				label: "Rolagem na página"
			},
			{
				value: "immediately",
				label: "Imediatamente"
			},
			{
				value: "never",
				label: "Somente quando clicar no ícone"
			}
		]

		const triggerActivationTimeOptions: ItemSelectProtocol[] = [
			{
				value: 1000,
				label: "1 segundo"
			},
			{
				value: 3000,
				label: "3 segundos"
			},
			{
				value: 5000,
				label: "5 segundos"
			},
			{
				value: 7000,
				label: "7 segundos"
			},
			{
				value: 9000,
				label: "9 segundos"
			}
		]

		const triggerActivationScrollOptions: ItemSelectProtocol[] = [
			{
				value: 0.05,
				label: "5%"
			},
			{
				value: 0.10,
				label: "10%"
			},
			{
				value: 0.30,
				label: "30%"
			},
			{
				value: 0.50,
				label: "50%"
			},
			{
				value: 0.75,
				label: "75%"
			},
			{
				value: 0.90,
				label: "90%"
			}
		]

		const viewAdditionalSelect = trigger_activation_type !== "immediately" &&
			trigger_activation_type !== "never"

		const getBaseMetricValue = () => {
			const baseMetric = +newTriggerRulesData?.trigger_activation_baseMetric
			if (trigger_activation_type === "scrollInPage") {
				if (Number.isInteger(baseMetric)) {
					handleTriggerRulesInputChange({ trigger_activation_baseMetric: 0.10 })
					return 0.10
				}
				return baseMetric
			}

			if (trigger_activation_type === "timeInPage") {
				if (!Number.isInteger(baseMetric)) {
					handleTriggerRulesInputChange({ trigger_activation_baseMetric: 5000 })
					return 5000
				}
				return baseMetric
			}
		}

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="trigger_activation_type"
						select
						label="Tipo de ativação"
						variant="standard"
						size="medium"
						fullWidth
						color="secondary"
						value={newTriggerRulesData.trigger_activation_type}
						onChange={({ target }) => handleTriggerRulesInputChange({
							trigger_activation_type: target.value as TriggerTypeProtocol
						})}
						error={!!validation.trigger_activation_type}
						helperText={validation.trigger_activation_type}
						onKeyDown={() => clearValidation("trigger_activation_type")}
					>
						{triggerActivationOptions.map((option, index) => (
							<MenuItem key={index} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				{
					viewAdditionalSelect &&
					<Grid item xs={12}>
						<TextField
							id="trigger_activation_baseMetric"
							select
							label="Quando ativar"
							variant="standard"
							size="medium"
							fullWidth
							color="secondary"
							value={
								getBaseMetricValue()
							}
							onChange={({ target }) => handleTriggerRulesInputChange({
								trigger_activation_baseMetric: +target.value as number
							})}
							error={!!validation.trigger_activation_baseMetric}
							helperText={validation.trigger_activation_baseMetric}
							onKeyDown={() => clearValidation("trigger_activation_baseMetric")}
						>
							{
								trigger_activation_type === "timeInPage"
									? triggerActivationTimeOptions.map((option, index) => (
										<MenuItem key={index} value={option.value}>
											{option.label}
										</MenuItem>
									))
									: triggerActivationScrollOptions.map((option, index) => (
										<MenuItem key={index} value={option.value}>
											{option.label}
										</MenuItem>
									))
							}
						</TextField>
					</Grid>}
			</Grid>
		)
	}

	const getHowManyTimesAppear = () => {
		const { trigger_repeat } = widgetData?.widgetConfigs.trigger_rules

		if (!trigger_repeat) {
			return "Seu método de ativação aparecerá aqui..."
		} else {
			const triggerRepeatOptions: ObjectLiteralValuesProtocol = {
				every: "Sempre",
				onceADay: "Uma vez por dia",
				onceAWeek: "Uma vez por semana",
				default: ""
			}
			const triggerRepeat = triggerRepeatOptions[trigger_repeat] ??
				triggerRepeatOptions.default

			return `${triggerRepeat}`
		}
	}

	const handleUploadAvatar = async ([avatarFile]: any, contactId: number) => {
		try {
			setLoadingUploadAvatar(true)

			const formData = new FormData()
			formData.append("avatarFile", avatarFile, `avatar_${contactId}`)

			const { data } = await ApiService.put(`/contact/avatar/${contactId}`, formData, {
				headers: {
					"Content-Type": "multipart/data"
				}
			})

			const newContacts = contactsData

			const contactIndex = contactsData.findIndex(contact => contact.id === contactId)

			newContacts[contactIndex] = {
				...contactsData[contactIndex],
				avatar: data.url
			}

			setContactsData(newContacts)
			reloadIframe()
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setLoadingUploadAvatar(false)
	}

	const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const closePopoverColor = () => {
		setAnchorEl(null)
	}

	const completeChangeButtonColor = (color: string) => {
		changeWidgetConfigData(
			color,
			"styles",
			"button_icon_background"
		)
		closePopoverColor()
	}

	useDidMount(() => {
		getWidgetData()
	})

	return (
		<>
			<Grid container spacing={2} className={classes.widgetEditorContainer}>

				<Grid container className={classes.mobileActionBar} alignItems="center" justify="center">
					<Fab
						className={classes.mobileActionButton}
						variant="extended"
						size="small"
						aria-label="add"
						onClick={handleToggleMobileLivePreview}
					>
						{
							isMobilePreviewActive ? (
								<>
									<ArrowForwardIosIcon fontSize="small" style={{ transform: "rotate(90deg)" }} className={classes.mobileActionIconBtn} />
										Editar
								</>)
								: (
									<>
										<ArrowForwardIosIcon fontSize="small" style={{ transform: "rotate(-90deg)" }} className={classes.mobileActionIconBtn} />
										Visualizar
									</>)
						}
						{
							isMobilePreviewActive ? "" : ""
						}
					</Fab>
				</Grid>

				<Loading
					loading={loadingWidget}
					customLoadingElement={<ChatWhatsappSkeleton />}
				>
					<Grid item xs={12} sm={5} lg={3} className={classes.panelEditorContainer}>

						{
							showAlreadyRegistered &&
							<>
								<Grid
									container
									alignItems="center"
									className={classes.alreadyRegisteredCaption}
								>
									<Typography
										variant='caption'
										align="center"
									>
										Já é cadastrado?
									</Typography>

									<Divider orientation="vertical" size={1} />

									<Link href="/signin" color="inherit">
										Faça login
									</Link>
								</Grid>

								<Divider size={1} orientation="horizontal" />
							</>
						}

						<Portlet elevation={1}>
							<Grid
								container
								justify="space-between"
								alignItems="center"
							>
								<Grid item xs>
									<Typography
										variant="body2"
										className={customClasses.textUppercase}
									>
										Ativado
									</Typography>
								</Grid>
								<Grid item>
									<Switch
										checked={widgetActive}
										onChange={handleWidgetStatus}
										color="secondary"
									/>
								</Grid>
							</Grid>
						</Portlet>

						<Divider size={2} orientation="horizontal" />
						<Portlet label="Número" elevation={1}>
							<List disablePadding>
								{
									contactsData.length
										? contactsData.map((contact: ContactsDataType, index: number) =>
											<ListItem key={index} button>
												<Dropzone
													onDrop={(files) => handleUploadAvatar(files, contact.id)}
												>
													{({ getRootProps, getInputProps }) => (
														<ListItemAvatar className={classes.avatarContainer} {...getRootProps()}>
															<Loading
																loading={loadingUploadAvatar}
																customLoadingElement={<UploadAvatarSkeleton />}
															>
																<>
																	<input
																		{...getInputProps()}
																		accept=".jpg, .jpeg, .png"
																		type="file"
																		hidden />
																	<Badge
																		overlap="circle"
																		anchorOrigin={{
																			vertical: "bottom",
																			horizontal: "right"
																		}}
																		badgeContent={<CameraIcon className={classes.avatarBadgeItem} />}
																	>
																		{contact.avatar
																			? <Avatar className={classes.avatarItem} alt={`${contact.name} - Avatar`} src={contact.avatar} />
																			: <Avatar className={classes.avatarItem}>{contact.name.substring(0, 1)}</Avatar>
																		}
																	</Badge>
																</>
															</Loading>
														</ListItemAvatar>
													)}
												</Dropzone>

												<ListItemText
													primary={contact.name}
													primaryTypographyProps={{
														className: classes.listItemTitle
													}}
													secondary={
														contact.contact
													}
													secondaryTypographyProps={{
														variant: "h3",
														color: "textPrimary",
														noWrap: true
													}}
													onClick={() => openContactDialog(contact)}
												/>

												<ListItemSecondaryAction className={classes.nextIcon}>
													<IconButton onClick={() => openContactDialog(contact)}>
														<ArrowForwardIosIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)
										: (
											<Grid
												container
												direction="column"
												alignContent="center"
												alignItems="center"
												justify="center"
												className={classes.createContactContainer}
											>
												<Grid item>
													<Fab
														onClick={handleAddContact}
														size="small"
														color="primary"
														aria-label="add"
													>
														<AddIcon />
													</Fab>
												</Grid>

												<Divider size={1} orientation="horizontal" />

												<Grid item>
													<Typography variant="caption">Cadastrar número</Typography>
												</Grid>
											</Grid>
										)
								}

							</List>
						</Portlet>

						<Divider size={3} orientation="horizontal" />

						<Portlet label="Personalização e exibição" elevation={1}>
							<List>
								<InputDialog
									title="Mudar mensagem principal"
									onDataChange={() => clearValidation("chat_main_message")}
									onOk={(chat_main_message) =>
										changeWidgetConfigData(
											chat_main_message,
											"general_content",
											"chat_main_message"
										)}
									initialValue={widgetData.widgetConfigs?.general_content?.chat_main_message}
									customInputElement={(
										<TextField
											error={!!validation.chat_main_message}
											helperText={validation.chat_main_message}
											label="Mensagem de Boas Vindas"
											variant="standard"
											color="secondary"
											multiline
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
									fullWidth
								>
									<ListItem divider button>
										<ListItemText
											primary="MENSAGEM DE BOAS VINDAS"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												widgetData.widgetConfigs?.general_content?.chat_main_message ?? "Sua mensagem aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<InputDialog
									title="Mudar texto do botão"
									onDataChange={() => clearValidation("chat_button_text")}
									onOk={(chat_button_text) =>
										changeWidgetConfigData(
											chat_button_text,
											"general_content",
											"chat_button_text"

										)}
									initialValue={widgetData.widgetConfigs?.general_content?.chat_button_text}
									customInputElement={(
										<TextField
											error={!!validation.chat_button_text}
											helperText={validation.chat_button_text}
											label="Texto do botão"
											variant="standard"
											color="secondary"
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
									fullWidth
								>
									<ListItem divider button>
										<ListItemText
											primary="TEXTO DO BOTÃO"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												widgetData.widgetConfigs?.general_content?.chat_button_text ?? "O texto aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<InputDialog
									title="Mudar posição"
									onDataChange={() => clearValidation("widget_position")}
									onOk={(widget_position) =>
										changeWidgetConfigData(
											widget_position,
											"display_rules",
											"widget_position"
										)}
									initialValue={widgetData.widgetConfigs?.display_rules?.widget_position}
									customInputElement={(
										<TextField
											id="widget_position"
											select
											label="Posição"
											color="secondary"
											error={!!validation.widget_position}
											helperText={validation.widget_position}
											fullWidth
										>
											{widgetPositionOptions.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									)}
									openOnDrawer={isMobile}
									fullWidth
								>
									<ListItem divider button>
										<ListItemText
											primary="POSIÇÃO"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												widgetData.widgetConfigs?.display_rules ? getWidgetPosition() : "A posição aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<ListItem button onClick={openPopover}>
									<ListItemText
										primary="COR DO BOTÃO"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={
											widgetData.widgetConfigs?.styles?.button_icon_background
										}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemIcon className={classes.nextIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemIcon>
								</ListItem>

								<Popover
									open={openColorPopover}
									anchorEl={anchorEl}
									onClose={closePopoverColor}
									anchorOrigin={{
										vertical: "center",
										horizontal: "right"
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "left"
									}}
								>
									<ColorPickerInput
										disableAlpha={true}
										initialColor={widgetData.widgetConfigs?.styles?.button_icon_background}
										onSave={completeChangeButtonColor}
										onCancel={closePopoverColor}
									/>
								</Popover>

							</List>
						</Portlet>

						<Divider size={3} orientation="horizontal" />

						<Portlet label="Chat ativo (POPUP)" elevation={1}>
							<List>
								<ListItem divider button onClick={() => openTriggerRulesDialog()}>
									<ListItemText
										primary="QUANDO APARECER"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={
											widgetData.widgetConfigs?.trigger_rules ? getWhenAppear()
												: "Seu método de ativação aparecerá aqui..."
										}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.nextIcon}>
										<IconButton onClick={() => openTriggerRulesDialog()}>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>

								<InputDialog
									title="Mudar repetição"
									onDataChange={() => clearValidation("trigger_repeat")}
									onOk={(trigger_repeat) =>
										changeWidgetConfigData(
											trigger_repeat,
											"trigger_rules",
											"trigger_repeat"
										)}
									initialValue={widgetData.widgetConfigs?.trigger_rules?.trigger_repeat}
									customInputElement={(
										<TextField
											id="trigger_repeat"
											select
											label="Quantas vezes aparecer"
											color="secondary"
											error={!!validation.trigger_repeat}
											helperText={validation.trigger_repeat}
											fullWidth
										>
											{widgetHowOftenAppearOptions.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									)}
									openOnDrawer={isMobile}
									fullWidth
								>
									<ListItem button>
										<ListItemText
											primary="QUANTAS VEZES APARECER"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												widgetData.widgetConfigs?.trigger_rules ? getHowManyTimesAppear()
													: "Seu método de ativação aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>
							</List>
						</Portlet>

						<Divider size={1} orientation="horizontal" />

						<ActionDialog
							loading={loadingPutContact}
							closable={!(selectContact.open)}
							title={
								selectContact.contactData?.id
									? "Alterar contato"
									: "Criar contato"
							}
							onSave={saveNewContact}
							onClose={closeContactDialog}
							fullWidth
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										id="name"
										label="Nome"
										variant="standard"
										size="medium"
										fullWidth
										required
										color="secondary"
										value={selectContact.contactData?.name}
										onChange={({ target }) => handleContactInputChange({ name: target.value })}
										error={!!validation.name}
										helperText={validation.name}
										onKeyDown={() => clearValidation("name")}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										id="contact"
										label="Número do Whatsapp"
										variant="standard"
										size="medium"
										fullWidth
										required
										color="secondary"
										value={selectContact.contactData?.contact}
										onChange={({ target }) => handleContactInputChange({ contact: formatPhoneNumber(target.value) })}
										error={!!validation.contact}
										helperText={validation.contact}
										onKeyDown={() => clearValidation("contact")}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										id="status_message"
										label="Recado"
										variant="standard"
										size="medium"
										fullWidth
										color="secondary"
										value={selectContact.contactData?.additional_info?.status_message}
										onChange={({ target }) => handleContactInputChange({
											additional_info: {
												...selectContact.additional_info,
												status_message: target.value
											}
										})}
										error={!!validation.status_message}
										helperText={validation.status_message}
										onKeyDown={() => clearValidation("status_message")}
									/>
								</Grid>
							</Grid>
						</ActionDialog>

						<ActionDialog
							loading={loadingPutTriggerRules}
							closable={!(newTriggerRulesData.trigger_activation_type)}
							title="Alterar quando aparecer"
							onSave={saveNewTriggerRules}
							onClose={closeTriggerRulesDialog}
							fullWidth
						>
							{newTriggerRulesData.trigger_activation_type && getChangeWhenAppearOptions()}
						</ActionDialog>
					</Grid>
				</Loading>

				<Grid
					item
					xs={12}
					sm={7}
					lg={9}
					className={`${classes.previewWidget} ${isMobile && classes.isMobilePreviewActive}`}>
					<Loading
						loading={loadingWidget}
					>
						<Grid container className={classes.iframeContainer}>
							<iframe
								id={livePreviewID}
								src={customerSite ?? `${externalConfig.widgetViewUrl}/${widgetData?.widget?.id}`}
								title="Widget Preview"
								frameBorder="0"
								referrerPolicy="same-origin"
								allow="true"
								className={classes.iframeContent}
							/>
						</Grid>
					</Loading>
				</Grid>
			</Grid>
		</>
	)
}

export default ChatWhatsappEditor
