import { createMuiTheme } from "@material-ui/core"

import colors from "@/styles/colors"

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
	palette: {
		background: {
			default: colors.palette.background,
			paper: colors.greyScale[12]
		},
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: "#FFF"
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		},
		text: {
			primary: colors.greyScale[1]
		}
	},
	overrides: {
		MuiLink: {
			root: {
				color: colors.palette.link,
				"&:hover": {
					textDecoration: "none !important",
					opacity: 0.7
				}
			}
		},
		MuiFab: {
			root: {
				boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.08)",
				backgroundColor: colors.greyScale[11]
			}
		},
		MuiInputLabel: {
			root: {
				fontFamily: "Open Sans",
				fontStyle: "normal",
				fontWeight: "normal",
				fontSize: "16px",
				lineHeight: "24px",
				textTransform: "none",
				color: colors.greyScale[4]
			}
		}
	},
	typography: {
		fontFamily: "Open Sans",
		h1: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "24px",
			lineHeight: "20px",
			color: colors.greyScale[2]
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "20px",
			lineHeight: "19px",
			letterSpacing: "0.07em",
			color: colors.greyScale[3]
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "14px",
			lineHeight: "19px",
			color: colors.greyScale[5]
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "12px",
			lineHeight: "24px",
			color: colors.greyScale[4]
		},
		h5: {
			fontStyle: "normal",
			fontWeight: "bold",
			fontSize: "11px",
			lineHeight: "16px"
		},
		h6: {
			fontSize: "1.25rem",
			fontFamily: "Open Sans",
			fontWeight: 500,
			lineHeight: 1.6
		},
		body1: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "16px",
			lineHeight: "16.5px",
			letterSpacing: "-3%",
			color: colors.greyScale[4]
		},
		body2: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "14px",
			lineHeight: "19px",
			letterSpacing: "3%",
			color: colors.greyScale[4]
		},
		overline: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "14px",
			lineHeight: "24px",
			textTransform: "none",
			color: colors.greyScale[4]
		},
		caption: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "15px",
			lineHeight: "16px",
			color: colors.greyScale[4]
		},
		button: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "14px",
			lineHeight: "24px"
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		0: "0px 0px 0px rgba(0, 0, 0, 0)",
		1: "0px 2px 4px rgba(33, 36, 41, 0.08)",
		2: "0px 4px 16px rgba(33, 36, 41, 0.24)"
		// eslint-disable-next-line
	}) as any
})

export default theme
