export const livePreviewID = "picsize-widget-preview"

export const getLivePreviewDocument = () => {
	const livePreview = document.getElementById(livePreviewID) as HTMLObjectElement

	let livePreviewDocument

	if (livePreview && livePreview.contentWindow && livePreview.contentWindow.document) {
		livePreviewDocument = livePreview.contentWindow.document
	}

	return livePreviewDocument
}

type InsertPurePropsType = {
	selector: string
	value: string
}

const useLivePreview = () => {
	/**
		*
		* @param {String} selector A selector for an existent parent node. Ex: #page-section-1
		* @param {String} value A html in string. Ex: <div><p>EAE</p></div>
		*/
	const insertPureHTML = ({ selector, value }: InsertPurePropsType) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const parentNode = livePreviewDocument.querySelector(selector)

			if (parentNode) {
				const tempParent = livePreviewDocument.createElement("div")
				tempParent.innerHTML = value

				parentNode.appendChild(tempParent.firstChild as ChildNode)
			}
		}
	}

	const livePreview = {
		insertPureHTML
	}

	return livePreview
}

export default useLivePreview
