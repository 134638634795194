import React, { useState } from "react"
import { CustomPicker, ChromePicker as ColorSelector } from "react-color"
import {
	Grid,
	Button
} from "@material-ui/core"
import { Divider } from "@/components"

import useStyles from "@/components/ColorPickerInput/styles"

type PropsType = {
	onSave: (color: string) => void
	disableAlpha: boolean
	initialColor: string
	onCancel: () => void
}

type ColorPropsType = {
	hex: string,
	rgb: {
		r: number,
		g: number,
		b: number,
		a: number,
	},
	hsl: {
		h: number,
		s: number,
		l: number,
		a: number,
	},
}

const ColorPickerInput = (props: PropsType) => {
	const { onSave, disableAlpha, initialColor, onCancel } = props
	const classes = useStyles()

	const [currentColor, setCurrentColor] = useState(initialColor)

	const handlePreviewChange = (color: ColorPropsType) => {
		setCurrentColor(color.hex)
	}

	const handleSave = () => {
		onSave(currentColor)
	}

	const handleCancel = () => {
		onCancel()
	}

	return (
		<Grid container direction="column">
			<Grid item xs={12} className={classes.colorPickerContainer}>
				<ColorSelector
					disableAlpha={disableAlpha}
					color={currentColor}
					onChange={(color) => handlePreviewChange(color as ColorPropsType)}
				/>
			</Grid>

			<Divider size={2} orientation="horizontal" />

			<Grid justify="space-between" container className={classes.buttonsContainer}>
				<Grid item>
					<Button
						className={classes.cancelButton}
						onClick={handleCancel}
						color="inherit"
					>
						Cancelar
					</Button>
				</Grid>

				<Grid item>
					<Button
						onClick={handleSave}
						variant="contained"
						color="secondary"
						disabled={currentColor === initialColor}
					>
						Salvar
					</Button>
				</Grid>
			</Grid>

			<Divider size={2} orientation="horizontal" />
		</Grid>
	)
}

export default CustomPicker(ColorPickerInput)
