import React from "react"
import { Route } from "react-router-dom"

import Integration from "@/pages/Integration"

import Signup from "@/pages/Auth/SignUp"
import SignIn from "@/pages/Auth/SignIn"

import RecoveryRequest from "@/pages/Auth/Recovery/Request"
import RecoveryReset from "@/pages/Auth/Recovery/Reset"

const PublicRoutes = [
	<Route
		exact
		key="integration"
		path="/integration/login/:loginToken/:widgetId"
		component={Integration}
	/>,
	<Route
		key="signup"
		path="/signup"
		component={Signup}
	/>,
	<Route
		key="signin"
		path="/signin"
		component={SignIn}
	/>,
	<Route
		key="recovery-request"
		path="/recovery/request"
		component={RecoveryRequest}
	/>,
	<Route
		key="recovery-reset"
		path="/recovery/reset"
		component={RecoveryReset}
	/>
]

export default PublicRoutes
