import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import { isMobile } from "@/utils/checkDevice"

const useStyles = makeStyles(theme => ({
	cardContainer: {
		borderRadius: theme.spacing() * 2,
		border: `${theme.spacing()}px solid transparent`,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	cardMedia: {
		height: 0,
		paddingTop: isMobile ? "110%" : "90%",
		backgroundColor: colors.greyScale[9],
		backgroundPosition: "top center"
	},
	cardFooterButtonsContainer: {
		marginTop: theme.spacing(1)
	},
	cardFooterButton: {
		color: colors.greyScale[5],
		fontWeight: "bold"
	},
	cardActionWrapper: {
		padding: theme.spacing(2),
		display: "flex",
		justifyContent: "center"
	},
	cardSelect: {
		border: `4px solid ${colors.palette.confirm}`,
		borderRadius: "4px",
		padding: `${theme.spacing(1)}px !important`,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.shortest
		})
	}
}))

export default useStyles
