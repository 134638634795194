import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	CircularProgress,
	Container,
	Grid,
	InputLabel,
	TextField,
	Typography,
	Link
} from "@material-ui/core"

import {
	Portlet,
	Divider
} from "@/components"

import AuthService from "@/services/Auth"
import { SignInData } from "@/Protocols/AuthProtocol"
import ApiService from "@/services/Api"

import useValidation from "@/hooks/useValidation"
import useAuthStyles from "@/pages/Auth/styles"
import useDidMount from "@/hooks/useDidMount"

const SignIn = () => {
	const [signInData, setSignInData] = useState<SignInData>({} as SignInData)
	const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false)

	const authClasses = useAuthStyles()
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()
	const history = useHistory()

	const handleChangeSingInData = (key: keyof SignInData, value: string) => {
		setSignInData(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setLoadingSignIn(true)

		try {
			const { data } = await ApiService.post("/auth/signin", signInData)

			const authToken = data.authToken

			AuthService.login(authToken, "/my-widgets")
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSignIn(false)
	}

	const isLogged = async () => {
		const { data } = await ApiService.get("/customer")

		if (data.type !== "anonymous") {
			history.push("/my-widgets")
		}
	}

	useDidMount(() => {
		isLogged()
	})

	return (
		<Container maxWidth={"sm"} className={authClasses.authContainer}>
			<Portlet className={authClasses.authContent}>
				<Typography
					variant="h6"
					className={authClasses.authTitle}
					align="center"
				>
					LOGIN
				</Typography>

				<Divider size={3} orientation="horizontal" />

				<Grid
					container
					component="form"
					onSubmit={handleSubmit}
				>
					<InputLabel className={authClasses.authLabel}>Email</InputLabel>
					<Divider orientation="horizontal" size={1} />

					<TextField
						variant="outlined"
						value={signInData.email}
						placeholder="Digite seu email aqui..."
						onChange={({ target }) => handleChangeSingInData("email", target.value.toLocaleLowerCase())}
						helperText={validation.email}
						error={!!validation.email}
						fullWidth
						color="secondary"
						size="medium"
						autoFocus
					/>

					<Divider orientation="horizontal" size={2} />

					<InputLabel className={authClasses.authLabel}>Senha</InputLabel>
					<Divider orientation="horizontal" size={1} />

					<TextField
						variant="outlined"
						value={signInData.password}
						placeholder="Digite sua senha aqui..."
						onChange={({ target }) => handleChangeSingInData("password", target.value)}
						helperText={validation.password}
						error={!!validation.password}
						fullWidth
						type="password"
						color="secondary"
						size="medium"
						autoFocus
					/>

					<Grid item style={{ width: "100%" }}>
						<Grid container justify="flex-end" alignItems="center">
							<Typography
								variant='caption'
								align="right"
							>
								Esqueceu a senha?
							</Typography>

							<Divider orientation="vertical" size={1} />

							<Link href="/recovery/request" color="inherit">
								Clique aqui
							</Link>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={4} />

					<Grid item className={authClasses.authCenter}>
						<Grid container justify="center" alignContent="center">
							<Button
								variant="contained"
								color="secondary"
								disabled={loadingSignIn}
								endIcon={loadingSignIn && (
									<CircularProgress size={20} color="inherit" />
								)}
								type="submit"
								className={authClasses.authCenter}
							>
								Entrar
							</Button>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={2} />

					<Grid item className={authClasses.authCenter}>
						<Grid container justify="center" alignItems="center">
							<Link href="/signup" color="inherit">
								Continuar e cadastrar depois
							</Link>
						</Grid>
					</Grid>

				</Grid>

			</Portlet>
		</Container>
	)
}

export default SignIn
