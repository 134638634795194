import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const topHeaderDesktop = "49px"

const useStyles = makeStyles(theme => ({
	topHeaderWrapper: {
		height: `calc(${topHeaderDesktop} + 10px)`,
		background: colors.greyScale[11],
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			height: "auto",
			maxHeight: "none"
		}
	},
	topHeaderTitleContent: {
		padding: theme.spacing(0, 1),
		height: "100%",
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1),
			justifyContent: "space-between"
		}
	},
	topHeaderImage: {
		display: "flex",
		marginRight: theme.spacing(2),
		"& > img": {
			width: "auto",
			maxHeight: `calc(${topHeaderDesktop} - 25px)`
		}
	},
	topHeaderTitle: {
		borderLeft: "3px solid #393737",
		paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(1)
	},
	topHeaderButtonContainer: {
		maxHeight: `calc(${topHeaderDesktop} + 10px)`,
		textAlign: "end",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
			margin: theme.spacing(2)
		}
	},
	topHeaderButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none"
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: colors.greyScale[5]
	},
	signUpContent: {
		padding: theme.spacing(3, 4)
	}
}))

export default useStyles
