import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Grid,
	Button,
	Typography,
	InputLabel,
	TextField,
	CircularProgress,
	Link
} from "@material-ui/core"

import { Divider } from "@/components"

import useStyles from "@/pages/Auth/components/AnonymousSignUp/styles"

import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"

import useValidation from "@/hooks/useValidation"

import { SignUpData } from "@/Protocols/AuthProtocol"
import { WidgetDataResponseType } from "@/Protocols/WidgetProtocol"

const AnonymousSignUp: React.FC = () => {
	const [signUpData, setSignUpData] = useState<SignUpData>({} as SignUpData)
	const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)

	const classes = useStyles()
	const history = useHistory()
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const handleChangeSingUpData = (key: keyof SignUpData, value: string) => {
		setSignUpData(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setLoadingSignUp(true)

		try {
			await ApiService.post("/auth/anonymous/signup", signUpData)

			const widget = StorageService.get("customer-widget-data") as WidgetDataResponseType

			history.push(`/my-widgets?widgetId=${widget.id}`)
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSignUp(false)
	}

	return (
		<>
			<Typography
				variant="h6"
				className={classes.signUpTitle}
			>
				Cadastre-se e receba o código para inserir em seu site
			</Typography>
			<Divider orientation="horizontal" size={1} />
			<Typography variant="body1">O cadastro é essencial para no futuro você conseguir acompanhar as métricas e fazer ajustes. </Typography>

			<Divider size={3} orientation="horizontal" />

			<Grid
				container
				component="form"
				onSubmit={handleSubmit}
			>
				<InputLabel className={classes.signUpLabel}>Nome</InputLabel>
				<Divider orientation="horizontal" size={1} />

				<TextField
					variant="outlined"
					value={signUpData.name}
					placeholder="Digite seu nome aqui..."
					onChange={({ target }) => handleChangeSingUpData("name", target.value)}
					helperText={validation.name}
					error={!!validation.name}
					fullWidth
					color="secondary"
					size="medium"
					autoFocus
				/>

				<Divider orientation="horizontal" size={2} />

				<InputLabel className={classes.signUpLabel}>E-mail</InputLabel>
				<Divider orientation="horizontal" size={1} />

				<TextField
					variant="outlined"
					value={signUpData.email}
					placeholder="Digite seu e-mail aqui..."
					onChange={({ target }) => handleChangeSingUpData("email", target.value.toLowerCase())}
					helperText={validation.email}
					error={!!validation.email}
					fullWidth
					color="secondary"
					size="medium"
					autoFocus
				/>

				<Divider orientation="horizontal" size={2} />

				<InputLabel className={classes.signUpLabel}>Senha</InputLabel>
				<Divider orientation="horizontal" size={1} />

				<TextField
					variant="outlined"
					value={signUpData.password}
					placeholder="Digite sua senha aqui..."
					onChange={({ target }) => handleChangeSingUpData("password", target.value)}
					helperText={validation.password}
					error={!!validation.password}
					fullWidth
					color="secondary"
					size="medium"
					type="password"
					autoFocus
				/>

				<Divider orientation="horizontal" size={4} />

				<Grid item className={classes.signUpCenter}>
					<Grid container justify="center" alignContent="center">
						<Button
							variant="contained"
							color="secondary"
							disabled={loadingSignUp}
							endIcon={loadingSignUp && (
								<CircularProgress size={20} color="inherit" />
							)}
							type="submit"
							className={classes.signUpCenter}
						>
							Iniciar o teste grátis
						</Button>
						<Divider orientation="horizontal" size={1} />
						<Typography
							variant='caption'
							align="center"
						>
							Você receberá o código de inserção imediatamente após o cadastro.
						</Typography>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid item className={classes.signUpCenter}>
					<Grid container justify="center" alignItems="center">
						<Typography
							variant='caption'
							align="center"
						>
							Já é cadastrado?
						</Typography>

						<Divider orientation="vertical" size={1} />

						<Link href="/signin" color="inherit">
							Faça login
						</Link>
					</Grid>
				</Grid>

			</Grid>
		</>
	)
}

export default AnonymousSignUp
