import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	signUpTitle: {
		color: colors.greyScale[1],
		fontWeight: 400
	},
	signUpLabel: {
		fontWeight: "bold"
	},
	signUpCenter: {
		margin: "0 auto"
	},
	captionQuestion: {
		marginRight: theme.spacing(0.5)
	}
}))

export default useStyles
