import React, { useState } from "react"

import useDidMount from "@/hooks/useDidMount"
import WidgetChat from "@/pages/WidgetChat"
import ApiService from "@/services/Api"

const ProofsOfConcepts = () => {
	const [widgetConcept, setWidgetConcept] = useState<number | null>(null)

	const getWidgetData = async () => {
		const { data } = await ApiService.get("/widgets")

		setWidgetConcept(data[0].id)
	}

	useDidMount(() => {
		getWidgetData()
	})
	return (
		<>
			{widgetConcept &&
				<WidgetChat
					widgetData={{
						widgetId: widgetConcept,
						widgetType: "proofConcept"
					}}
				/>}
		</>
	)
}

export default ProofsOfConcepts
