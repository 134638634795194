import React from "react"
import {
	Card,
	CardProps,
	CircularProgress,
	Grid,
	Typography
} from "@material-ui/core"

import { Divider } from "@/components"

import useCustomStyles from "@/styles/custom"
import useStyles from "./styles"

type PortletProps = CardProps & {
	label?: string
	elevation?: number
	loading?: boolean
	style?: React.CSSProperties
}

const Portlet: React.FC<PortletProps> = (props) => {
	const { label, children, loading, style, ...rest } = props

	const customClasses = useCustomStyles()
	const classes = useStyles({ loading })

	if (loading) {
		return (
			<Card
				className={classes.card}
				elevation={props.elevation || 2}
				style={{
					...style,
					pointerEvents: "none"
				}}
				{...rest}
			>
				<Grid
					container
					className={classes.cardLoadingContainer}
				>
					<CircularProgress />
				</Grid>

				{children}
			</Card>
		)
	}

	return (
		<>
			{
				label && (
					<>
						<Typography variant="h3" className={`${customClasses.textUppercase} ${classes.labelPortlet}`}>
							{label}
						</Typography>
						<Divider size={2} orientation="horizontal" />
					</>
				)
			}

			<Card
				className={classes.card}
				elevation={props.elevation || 2}
				style={{ ...style }}
				{...rest}
			>
				{children}
			</Card>
		</>
	)
}

export default Portlet
