import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import WidgetChat from "@/pages/WidgetChat"
import MyWidgets from "@/pages/MyWidgets"
import ProofsOfConcept from "@/pages/ProofsOfConcepts"

const PrivateRoutes = () => (
	<Switch>
		<Redirect exact path="/" to="/my-widgets" />

		<Route exact path="/widget-editor/:widgetId" component={WidgetChat} />
		<Route exact path="/my-widgets" component={MyWidgets} />
		<Route exact path="/proof-concepts" component={ProofsOfConcept} />
	</Switch>
)

export default PrivateRoutes
