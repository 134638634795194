import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useSignUpStyles = makeStyles(theme => ({
	wizardContainer: {
		background: "none"
	},
	stepContainer: {
		flex: 1
	},
	stepContent: {
		padding: theme.spacing(2, 0, 0),
		maxWidth: "100vw"
	},
	buttonFooterContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		width: "100%",
		background: colors.greyScale[12]
	},
	buttonFooterWrapper: {
		padding: theme.spacing(2, 4)
	}
}))

export default useSignUpStyles
