import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Grid,
	Typography,
	Button,
	Card,
	Container,
	CardMedia,
	CardActions
} from "@material-ui/core"

import useValidation from "@/hooks/useValidation"
import {
	Divider, Loading
} from "@/components"

import useStyles from "@/pages/Auth/SignUp/Step-1/styles"
import useSignUpStyles from "@/pages/Auth/SignUp/styles"

import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"
import useDidMount from "@/hooks/useDidMount"

const StepOne: React.FC = () => {
	const [selectTemplate, setSelectTemplate] = useState<string>("chatWhatsApp")
	const [loadingCreateWidget, setLoadingCreateWidget] = useState<boolean>(true)

	const classes = useStyles()
	const signUpClasses = useSignUpStyles()
	const history = useHistory()
	const validation = useValidation()

	const handleSelectTemplate = (templateType: string) => {
		setSelectTemplate(templateType)
	}

	const createWidget = async () => {
		setLoadingCreateWidget(true)
		try {
			const { data } = await ApiService.post(`/widget/${selectTemplate}`)

			StorageService.set("customer-widget-data", data)

			history.push("/signup/step-two")
		} catch (error) {
			validation.triggerValidation(error)
		}
		setLoadingCreateWidget(false)
	}

	useDidMount(() => {
		createWidget()
	})

	// const handleNextStep = () => {
	// 	if (!selectTemplate) {
	// 		Notification.warning({ message: "Escolha um tema para prosseguir" })
	// 	} else {
	// 		createWidget()
	// 	}
	// }

	return (
		<Loading
			loading={loadingCreateWidget}
		>
			<Grid item style={{ flex: 1 }}>
				<Grid
					container
					className={signUpClasses.stepContent}
				>
					<Container maxWidth={"lg"}>
						<Typography>
							Escolha um modelo para iniciar, depois você poderá ajustar as configurações e gatilhos, layout e cores de acordo com a sua demanda.
						</Typography>

						<Divider orientation="horizontal" size={4} />

						<Grid item xs={12}>

							<Grid container spacing={3}>
								<Grid
									item
									xs={6} sm={4} lg={3}
									className={
										`${selectTemplate === "chatWhatsApp" && classes.cardSelect}`
									}>
									<>
										<Card
											elevation={1}
											className={classes.cardContainer}
										>
											<CardMedia
												image={"https://static.whatsapp.net/rsrc.php/v3/yO/r/FsWUqRoOsPu.png"}
												title={"Whatsapp"}
												className={classes.cardMedia}
											/>

											<CardActions className={classes.cardActionWrapper}>
												<Button
													className={classes.cardFooterButton}
													onClick={() => handleSelectTemplate("chatWhatsApp")}
												>
													Escolher este
												</Button>
											</CardActions>
										</Card>
									</>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>

			{/* <Grid item className={signUpClasses.buttonFooterContainer}>
				<Grid container justify="flex-end" className={signUpClasses.buttonFooterWrapper}>
					<Grid item>
						<Button
							onClick={handleNextStep}
							variant="contained"
							color="secondary"
							disabled={loadingCreateWidget}
							endIcon={
								loadingCreateWidget && (
									<CircularProgress size={20} color="primary" />
								)}
						>
							Proximo
						</Button>
					</Grid>
				</Grid>
			</Grid> */}

		</Loading>
	)
}

export default StepOne
