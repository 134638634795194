import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useAuthStyles = makeStyles(theme => ({
	authContainer: {
		margin: "auto"
	},
	authContent: {
		padding: theme.spacing(2)
	},
	authTitle: {
		color: colors.greyScale[1],
		fontWeight: 400
	},
	authLogoImage: {
		display: "flex",
		margin: "0 auto"
	},
	authLabel: {
		fontWeight: "bold"
	},
	authItem: {
		width: "100%"
	},
	authCenter: {
		margin: "0 auto"
	}
}))

export default useAuthStyles
