import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core"
import { Divider, Portlet } from "@/components"
import useCustomStyles from "@/styles/custom"

const useStyles = makeStyles((theme) => ({
	paddingSkeleton: {
		padding: theme.spacing(1)
	}
}))

const ChatWhatsappSkeleton = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<Grid item xs={12} sm={5} lg={3}>

			<Portlet elevation={1}>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={classes.paddingSkeleton}
				>
					<Grid item xs>
						<Typography
							variant="body2"
							className={customClasses.textUppercase}
						>
							Ativado
						</Typography>
					</Grid>
					<Grid item>
						<Skeleton variant="rect" width={100} height={25} animation="wave" />
					</Grid>
				</Grid>
			</Portlet>

			<Divider size={2} orientation="horizontal" />

			<Portlet label="Número" elevation={1}>
				<List>
					<ListItem>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>
				</List>
			</Portlet>

			<Divider size={2} orientation="horizontal" />

			<Portlet label="Personalização e exibição" elevation={1}>
				<List disablePadding>

					<ListItem divider>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>

					<ListItem divider>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>

					<ListItem>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>
				</List>
			</Portlet>

			<Divider size={2} orientation="horizontal" />

			<Portlet label="Chat ativo (POPUP)" elevation={1}>
				<List disablePadding>

					<ListItem divider>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>

					<ListItem>
						<ListItemIcon>
							<Skeleton animation="wave" variant="circle" width={35} height={35} />
						</ListItemIcon>

						<ListItemText>
							<Skeleton variant="rect" width={100} height={20} animation="wave" />
							<Divider size={1} orientation="horizontal" />
							<Skeleton variant="rect" width={"100%"} height={20} animation="wave" />
						</ListItemText>
					</ListItem>

				</List>
			</Portlet>
		</Grid>
	)
}

export default ChatWhatsappSkeleton
