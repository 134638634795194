import axios from "axios"
import axiosRetry from "axios-retry"

import AuthService from "@/services/Auth"

import apiConfig from "@/config/api"

const ApiService = axios.create({
	baseURL: apiConfig.apiUrl
})

axiosRetry(ApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

ApiService.interceptors.request.use(config => {
	if (AuthService.isLoggedIn) {
		config.headers[AuthService.authTokenKey] = AuthService.authToken
	}

	return config
})

export default ApiService
