import React, { createContext, useContext, useState } from "react"

type ExampleData = {
	example_id: number
}

export interface GlobalStateContextData {
	example: ExampleData
	setExampleData: (data: ExampleData) => void
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [example, setExample] = useState<ExampleData>({} as ExampleData)

	const setExampleData = (
		data: ExampleData
	) => {
		setExample(lastState => ({
			...lastState,
			...data
		}))
	}

	return (
		<GlobalStateStore.Provider
			value={{
				example,
				setExampleData
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
