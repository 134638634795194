import GoogleTagManager from "react-gtm-module"

import GoogleConfig from "@/config/google"
import { isDevEnv } from "@/utils/environment"

type GoogleTagManagerLayerData = {}

export type GoogleTagManagerProps = {
	dataLayer?: GoogleTagManagerLayerData
}

// eslint-disable-next-line
const customWindow = window as any

const triggerGoogleTagManagerDOMLoadedEvent = () => {
	const DOMLoaded = {
		event: "gtm.dom",
		"gtm.uniqueEventId": 100
	}

	let lastGtmUniqueEventId = 0

	Object.keys(customWindow.dataLayer).forEach((key, index) => {
		if (key === "gtm.uniqueEventId") {
			lastGtmUniqueEventId = customWindow.dataLayer[index]["gtm.uniqueEventId"] + 1
		}
	})

	DOMLoaded["gtm.uniqueEventId"] = lastGtmUniqueEventId

	customWindow.onload = customWindow.dataLayer.push(DOMLoaded)
}

export const addDataLayerVariables = async (dataLayer: GoogleTagManagerLayerData) => {
	if (customWindow.dataLayer) {
		customWindow.dataLayer.push(dataLayer)
	} else {
		customWindow.dataLayer = [dataLayer]
	}
	/**
	 * Adds a DOM Loaded event on GTM, since that"s a SPA and
	 * gtm doesn't recognize by itself.
	 */
	triggerGoogleTagManagerDOMLoadedEvent()
}

export const initGoogleTagManager = (): void => {
	if (!isDevEnv) {
		GoogleTagManager.initialize({
			gtmId: GoogleConfig.gtmId
		})
	}
}

export const triggerGoogleAnalyticsPageView = (pathname: string): void => {
	if (!isDevEnv) {
		// As we're adding google analytics using google tag manager, we
		// can just use this function after the google tag manager added it
		// to the window scope.

		if (customWindow.ga) {
			customWindow.ga("send", "pageview", pathname)
		}
	}
}
