import React, { useState } from "react"

import { Switch, Route, useHistory, useLocation } from "react-router-dom"
import { Container } from "@material-ui/core"

import PrivateRoutes from "@/routes/private"
import PublicRoutes from "@/routes/public"

import { Loading } from "@/components"

import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"
import { initGoogleTagManager, triggerGoogleAnalyticsPageView } from "@/services/Google"
import ErrorHandlerService from "@/services/ErrorHandler"

const pagesWithFullScreen = ["widget-editor", "signup"]

const PrivateComponent = () => {
	const [loading, setLoading] = useState(true)

	const location = useLocation()
	const history = useHistory()

	const getInitialData = async () => {
		try {
			const { data } = await ApiService.get("/customer")

			if (data.type === "anonymous") {
				history.push("/signup")
			}
		} catch (error) {
			history.push("/signup")
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	useDidMount(() => {
		getInitialData()
	})

	const pathName = location.pathname.split("/")

	return (
		<Loading loading={loading}>
			<Container maxWidth={false} disableGutters style={{ display: "flex" }}>
				<Container
					maxWidth={false}
					disableGutters
					style={{
						height: "100%",
						width: "100vw"
					}}
				>
					<Container
						maxWidth={
							pagesWithFullScreen.includes(pathName[1])
								? false
								: "lg"}
						style={{ overflow: "hidden", padding: 8, height: "100%" }}>
						<PrivateRoutes />
					</Container>
				</Container>
			</Container>
		</Loading>
	)
}

const Routes = () => {
	const history = useHistory()

	history.listen(({ pathname }) => {
		triggerGoogleAnalyticsPageView(pathname)
	})

	useDidMount(() => {
		initGoogleTagManager()
	})

	return (
		<Switch>
			{PublicRoutes}
			<Route path="/" component={PrivateComponent} />
			<Route path="*" component={() => <h1>Page Not Found</h1>} />
		</Switch>
	)
}

export default Routes
