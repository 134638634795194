import React, { useState } from "react"
import { Button, CircularProgress, Container, Grid, InputLabel, TextField } from "@material-ui/core"

import { Divider, Notification } from "@/components"

import ApiService from "@/services/Api"
import { RecoveryData } from "@/Protocols/AuthProtocol"
import useValidation from "@/hooks/useValidation"

import useAuthStyles from "@/pages/Auth/styles"

import logoImg from "@/assets/images/logos/letalk-logo.svg"

const RecoveryRequest = () => {
	const [recoveryData, setRecoveryData] = useState<RecoveryData>({} as RecoveryData)
	const [loadingRecovery, setLoadingRecovery] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const authClasses = useAuthStyles()

	const handleChangeRecoveryData = (key: keyof RecoveryData, value: string) => {
		setRecoveryData(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setLoadingRecovery(true)

		try {
			await ApiService.post("/auth/password/recovery", recoveryData)

			Notification.success({
				message: "Email de redefinição de senha enviado com sucesso!"
			})
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingRecovery(false)
	}

	return (
		<Container
			maxWidth={"xs"} className={authClasses.authContainer}
		>
			<Grid
				container
				justify="center"
				component="form"
				onSubmit={handleSubmit}
			>
				<Grid item className={authClasses.authItem}>
					<img
						src={logoImg}
						alt="Logo"
						className={authClasses.authLogoImage}
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid item className={authClasses.authItem}>
					<InputLabel className={authClasses.authLabel}>Email</InputLabel>
					<Divider orientation="horizontal" size={1} />
					<TextField
						variant="outlined"
						value={recoveryData.email}
						placeholder="Digite seu email aqui..."
						onChange={({ target }) => handleChangeRecoveryData("email",
							target.value.toLocaleLowerCase())}
						helperText={
							validation.email ||
							"Nesse e-mail você receberá uma mensagem com as instruções de recuperação de senha."}
						error={!!validation.email}
						fullWidth
						color="secondary"
						size="medium"
						autoFocus
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid item className={authClasses.authItem}>
					<Button
						variant="contained"
						color="secondary"
						disabled={loadingRecovery}
						endIcon={loadingRecovery && (
							<CircularProgress size={20} color="inherit" />
						)}
						type="submit"
						className={authClasses.authCenter}
						fullWidth
					>
						Recuperar senha
					</Button>
				</Grid>

			</Grid>
		</Container>
	)
}

export default RecoveryRequest
