import IMask from "imask"

export const formatPhoneNumber = (rawNumber: string) => {
	let formattedPhoneNumber: string = rawNumber

	if (rawNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: [
				{
					mask: "+55 (00) 00000-0000"
				},
				{
					mask: "+55 (00) 0000-0000"
				}
			]
		})

		formattedPhoneNumber = phoneNumberMask.resolve(rawNumber)
	}

	return formattedPhoneNumber
}

export const formatUrl = (rawUrl: string) => {
	let formattedUrl: string = rawUrl

	if (rawUrl) {
		const validPrefixes = ["https", "http"]

		// eslint-disable-next-line
		const asterisks = [...new Array(rawUrl.length)].map(_ => "*").join("")

		const defaultPrefix = validPrefixes[0]

		const currentUsedPrefixIndex = validPrefixes.findIndex(prefix => rawUrl.startsWith(prefix))

		let prefix: string

		if (currentUsedPrefixIndex === -1) {
			prefix = defaultPrefix
		} else {
			prefix = validPrefixes[currentUsedPrefixIndex]
		}

		const urlMask = IMask.createMask({
			mask: `${prefix}://${asterisks}`
		})

		formattedUrl = urlMask.resolve(formattedUrl)

		formattedUrl = formattedUrl
			.replace(/\s/g, "")
	}

	return formattedUrl
}

export const formatEmail = (rawEmail: string) => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			.toLowerCase()
			.replace(/\s/g, "")
	}

	return formattedEmail
}
