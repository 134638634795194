import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	headerDialog: {
		position: "relative",
		background: "linear-gradient(180deg, #82D2FF 0%, #2D9CDB 100%)",
		padding: theme.spacing(4),
		width: "100vw"
	},
	imageHeaderWrapper: {
		minHeight: "124px"
	},
	titleHeaderDialog: {
		fontSize: "22px",
		lineHeight: "29px",
		color: colors.greyScale[12]
	},
	bodyDialog: {
		padding: theme.spacing(4),
		textAlign: "center",
		width: "100%"
	},
	wrapperCode: {
		background: "#F4F6F8",
		padding: theme.spacing(4, 2),
		borderRadius: "5px",
		cursor: "pointer",
		position: "relative",
		border: "1px solid transparent",
		"&:hover": {
			background: "#e5eff3",
			border: "1px solid #4bafe7"
		}
	},
	codeContent: {
		lineHeight: "22px",
		fontSize: "14px",
		textAlign: "left"
	},
	buttonDialogCode: {
		color: colors.greyScale[11],
		fontWeight: 600,
		textTransform: "none"
	},
	copyBox: {
		position: "absolute",
		top: 3,
		right: 3,
		width: "106px",
		height: "32px",

		display: "flex",
		alignItems: "center",

		borderRadius: "3px",
		backgroundColor: colors.greyScale[5],
		color: colors.greyScale[11],
		fontSize: "11px",
		cursor: "pointer",
		pointerEvents: "none",
		padding: theme.spacing(1),

		overflow: "hidden"
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0,
		color: colors.greyScale[12]
	},
	platformHelpButton: {
		flex: 1,
		height: "80px",
		width: "100%",
		border: "1px solid #ECECEC",
		padding: theme.spacing(3),
		"& img": {
			maxWidth: "100%",
			maxHeight: "100%"
		}
	},
	otherPlatformsText: {
		lineHeight: "24px",
		textAlign: "left",
		fontWeight: "bold",
		color: "#4B98A8"
	}
}))

export default useStyles
