import React from "react"

import { Container } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const GetCodeSkeleton = () => {
	return (
		<Container>
			<Skeleton animation="wave" variant="rect" width="100%" height={132} />
		</Container>
	)
}

export default GetCodeSkeleton
