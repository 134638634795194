import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const topHeaderDesktop = "60px"

const useStyles = makeStyles(theme => ({
	widgetItem: {
		padding: theme.spacing(2)
	},
	widgetItemCaptionTitle: {
		color: colors.greyScale[6]
	},
	widgetItemCaptionContent: {
		fontWeight: "bold"
	},
	actionWithoutMenu: {
		display: "block",
		[theme.breakpoints.between("sm", "md")]: {
			display: "none"
		}
	},
	editWidgetDialog: {
		overflowX: "hidden",
		background: "#f7f7f7"
	},
	actionWithMenu: {
		textAlign: "end",
		display: "none",
		[theme.breakpoints.between("sm", "md")]: {
			display: "block"
		}
	},
	previewWidgetContainer: {
		flex: 1,
		height: `calc(100vh - ${topHeaderDesktop})`,
		padding: theme.spacing(2, 1)
	}
}))

export default useStyles
