import React, { useState } from "react"
import {
	Grid,
	Container,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton
} from "@material-ui/core"

import {
	CloseOutlined as CloseIcon
} from "@material-ui/icons"

import StorageService from "@/services/Storage"
import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/pages/Auth/SignUp/Step-2/styles"

import { WidgetDataResponseType } from "@/Protocols/WidgetProtocol"
import WidgetChat from "@/pages/WidgetChat"

import { PreviewCode } from "@/components"
import AnonymousSignUp from "@/pages/Auth/components/AnonymousSignUp"
import WidgetHeaderButton from "@/components/Widgets/HeaderButton"

export type DialogTitleProps = {
	onClose: () => void
}

const StepTwo: React.FC = () => {
	const [widgetData, setWidgetData] = useState({} as WidgetDataResponseType)
	const [openViewCodeDialog, setOpenViewCodeDialog] = useState<boolean>(false)
	const [openSignUpDialog, setOpenSignUpDialog] = useState<boolean>(false)

	const classes = useStyles()

	const getWidgetData = () => {
		const widget = StorageService.get("customer-widget-data") as WidgetDataResponseType

		setWidgetData(widget)
	}

	const closeViewCodeDialog = () => {
		setOpenViewCodeDialog(false)
	}

	const handleCloseSignUp = () => {
		setOpenSignUpDialog(false)
	}

	const SignUpDialogTitle = (props: DialogTitleProps) => {
		const { onClose } = props
		return (
			<DialogTitle disableTypography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		)
	}

	useDidMount(() => {
		getWidgetData()
	})

	return (
		<Container style={{ width: "100vw" }} disableGutters maxWidth={false}>

			<Grid item className={classes.topHeaderContainer}>
				{
					widgetData?.id &&
					<WidgetHeaderButton
						title="Configuração do WhatsApp Chat"
						widgetId={widgetData.id}
					/>
				}
			</Grid>

			<Grid item className={classes.previewWidgetContainer}>
				{widgetData?.id &&
					<WidgetChat
						widgetData={{
							widgetId: widgetData.id,
							widgetType: widgetData.type,
							showAlreadyRegistered: true
						}}
					/>}
			</Grid>

			{
				widgetData?.id &&
				<PreviewCode
					isClosable={!openViewCodeDialog}
					widgetId={widgetData.id}
					onClose={closeViewCodeDialog}
				/>
			}

			<Dialog
				onClose={handleCloseSignUp}
				aria-labelledby="signup-dialog"
				open={openSignUpDialog}>
				{SignUpDialogTitle({ onClose: handleCloseSignUp })}
				<DialogContent className={classes.signUpContent}>
					<AnonymousSignUp />
				</DialogContent>
			</Dialog>

		</Container>
	)
}

export default StepTwo
