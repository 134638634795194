import React, { useState } from "react"

import {
	List,
	ListItem,
	Typography,
	Grid,
	Switch,
	Button,
	IconButton,
	Dialog,
	Menu,
	MenuItem,
	ListItemIcon
} from "@material-ui/core"

import {
	Edit as EditIcon,
	MoreVert as MenuIcon,
	VisibilityOutlined as ViewIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	Notification,
	Loading,
	PreviewCode
} from "@/components"

import {
	WidgetDataResponseType,
	WidgetStatusType
} from "@/Protocols/WidgetProtocol"

import useStyles from "@/pages/MyWidgets/styles"

import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import useValidation from "@/hooks/useValidation"

import widgetChatWhatsAppImg from "@/assets/images/widgetsIcons/chatWhatsAppIcon.svg"
import useQuery from "@/hooks/useQuery"
import WidgetChat from "@/pages/WidgetChat"
import WidgetHeaderButton from "@/components/Widgets/HeaderButton"

type WidgetSelectType = {
	widgetId?: number
	anchorEl: HTMLElement | null
}

type WidgetDialog = {
	widgetId?: number
	open: boolean
}

const MyWidgets = () => {
	const [widgetsData, setWidgetsData] = useState<WidgetDataResponseType[]>([])
	const [loadingGetWidgets, setLoadingGetWidgets] = useState<boolean>(true)

	const [viewCodeDialog, setViewCodeDialog] = useState<WidgetDialog>({
		open: false
	})
	const [editWidgetDialog, setEditWidgetDialog] = useState<WidgetDialog>({
		open: false
	})

	const [widgetSelect, setWidgetSelect] = React.useState<WidgetSelectType>({ anchorEl: null })

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, widgetId: number) => {
		setWidgetSelect({
			widgetId: widgetId,
			anchorEl: event.currentTarget
		})
	}

	const handleWidgetMenuClose = () => {
		setWidgetSelect({ anchorEl: null })
	}

	const query = useQuery()
	const classes = useStyles()
	const {
		triggerUndeterminedValidation
	} = useValidation()

	const editWidget = (widgetId: number) => {
		setEditWidgetDialog({ open: true, widgetId })
	}

	const openViewCodeDialog = (widgetId: number) => {
		setWidgetSelect({ anchorEl: null })
		setViewCodeDialog({ open: true, widgetId: widgetId })
	}

	const closeViewCodeDialog = () => {
		setViewCodeDialog({ open: false })
	}

	const verifyIfOpenCodeDialog = () => {
		const widgetId = query.get("widgetId")

		if (widgetId) {
			openViewCodeDialog(+widgetId)
		}
	}

	const getWidgets = async () => {
		setLoadingGetWidgets(true)
		try {
			const { data } = await ApiService.get("/widgets")

			editWidget(data[0].id)
			setWidgetsData(data)

			verifyIfOpenCodeDialog()
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
		setLoadingGetWidgets(false)
	}

	const handleWidgetStatus = async (widgetId: number, status: WidgetStatusType) => {
		setLoadingGetWidgets(true)
		try {
			const widgetStatus = status === "ACTIVE"
				? "INACTIVE"
				: "ACTIVE"

			await ApiService.put(`/widget/widget-status/${widgetId}`, {
				widget_status: widgetStatus
			})

			Notification.success({
				message: "Ativação alterada com sucesso!"
			})

			const newWidgetsData = widgetsData

			newWidgetsData[
				newWidgetsData
					.findIndex((widget) => widget.id === widgetId)
			]
				.status = widgetStatus

			setWidgetsData(newWidgetsData)
		} catch (error) {
			Notification.error({
				message: "Falhar ao alterar o status de ativação do widget"
			})
		}
		setLoadingGetWidgets(false)
	}

	useDidMount(() => {
		getWidgets()
	})

	return (
		<>
			<Typography>Meus Widgets</Typography>

			<Divider size={3} orientation="horizontal" />

			<Loading
				loading={loadingGetWidgets}
				customLoadingElement={<></>}
			>
				<List>
					{
						!!widgetsData.length &&
						widgetsData.map((widget, index) => (
							<ListItem key={index}>
								<Portlet>
									<Grid
										container
										alignItems="center"
										spacing={2}
										className={classes.widgetItem}
									>
										<Grid item xs={8} sm={5} lg={3}>
											<Grid container alignContent="center" alignItems="center">
												<Grid item>
													<img src={widgetChatWhatsAppImg} alt="WidgetChat - WhatsApp"
													/>
												</Grid>

												<Grid item>
													<Typography>WhatsTrigger</Typography>
													<Typography>+55 9 9954-4441 (+2)</Typography>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={4} sm={2} lg={2}>
											<Switch
												checked={widget.status === "ACTIVE"}
												onChange={() => handleWidgetStatus(widget.id, widget.status)}
												color="secondary"
											/>
										</Grid>

										<Grid item xs={6} sm={2} lg={2}>
											<Typography variant="caption" className={classes.widgetItemCaptionTitle}>
												EXIBIÇÕES
											</Typography>
											<Divider size={0.1} orientation="horizontal" />
											<Typography variant="caption" className={classes.widgetItemCaptionContent}>
												1.754
											</Typography>
										</Grid>

										<Grid item xs={6} sm={2} lg={2}>
											<Typography variant="caption" className={classes.widgetItemCaptionTitle}>
												CLIQUES
											</Typography>
											<Divider size={0.1} orientation="horizontal" />
											<Typography variant="caption" className={classes.widgetItemCaptionContent}>
												758
											</Typography>
										</Grid>

										<Grid
											item xs={12}
											sm
											lg
											className={classes.actionWithoutMenu}>
											<Grid
												container
												justify="flex-end"
												alignContent="center"
												alignItems="center">
												<Grid item>
													<Button onClick={() => openViewCodeDialog(widget.id)} variant="text">
														ADICIONAR EM MEU SITE
													</Button>
												</Grid>

												<Grid item>
													<IconButton onClick={() => editWidget(widget.id)}>
														<EditIcon />
													</IconButton>
												</Grid>
											</Grid>
										</Grid>

										<Grid item sm={1} className={classes.actionWithMenu}>
											<IconButton
												aria-controls="widget-menu"
												aria-haspopup="true"
												onClick={(event) => handleMenuClick(event, widget.id)}
											>
												<MenuIcon />
											</IconButton>
										</Grid>

									</Grid>
								</Portlet>
							</ListItem>
						))
					}

					<Menu
						id="widget-menu"
						anchorEl={widgetSelect.anchorEl}
						keepMounted
						open={Boolean(widgetSelect.anchorEl)}
						onClose={handleWidgetMenuClose}
					>
						<MenuItem onClick={() => openViewCodeDialog(widgetSelect.widgetId as number)}>
							<ListItemIcon>
								<ViewIcon />
							</ListItemIcon>
							Adicionar ao meu site
						</MenuItem>
						<MenuItem onClick={() => editWidget(widgetSelect.widgetId as number)}>
							<ListItemIcon>
								<EditIcon />
							</ListItemIcon>
							Editar
						</MenuItem>
					</Menu>
				</List>
			</Loading>

			{
				viewCodeDialog?.widgetId &&
				<PreviewCode
					isClosable={!viewCodeDialog.open}
					widgetId={viewCodeDialog.widgetId}
					onClose={closeViewCodeDialog}
				/>
			}
			<Dialog
				fullScreen
				open={editWidgetDialog.open}
				aria-labelledby="edit-widget-dialog"
				className={classes.editWidgetDialog}
				classes={{
					paperFullScreen: classes.editWidgetDialog
				}}
			>
				<Grid
					direction="column"
					container>
					<Grid item>
						{editWidgetDialog?.widgetId &&
							<WidgetHeaderButton
								title="Configuração do WhatsApp Chat"
								widgetId={editWidgetDialog.widgetId}
							/>
						}
					</Grid>

					<Grid item className={classes.previewWidgetContainer}>
						{editWidgetDialog?.widgetId &&
							<WidgetChat
								widgetData={{
									widgetId: editWidgetDialog.widgetId,
									widgetType: "chatWhatsApp"
								}}
							/>}
					</Grid>
				</Grid>
			</Dialog>
		</>
	)
}

export default MyWidgets
