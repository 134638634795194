import { Theme, makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import BackgroundTransparentGrid from "@/assets/images/background/transparentGrid.svg"

const mobileActionBarHeight = 40

export interface StyleProps {
	isMobilePreviewActive: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	widgetEditorContainer: {
		height: "100%",
		width: "100vw"
	},
	panelEditorContainer: {
		height: "100%",
		display: "block",
		maxHeight: "98vh",
		overflow: "auto",
		[theme.breakpoints.down("xs")]: {
			display: props => !props.isMobilePreviewActive ? "block" : "none",
			maxHeight: "100%"
		}
	},
	listItemTitle: {
		color: colors.greyScale[5],
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "16px",
		letterSpacing: "-0.03em"
	},
	nextIcon: {
		color: colors.greyScale[5],
		minWidth: "0px"
	},
	createContactContainer: {
		padding: theme.spacing(1)
	},
	previewWidget: {
		paddingTop: 0
	},
	iframeContainer: {
		overflow: "hidden",
		paddingTop: "58%",
		position: "relative",
		[theme.breakpoints.down("lg")]: {
			height: "calc(96vh - 70px)"
		}
	},
	iframeContent: {
		border: 0,
		height: "100%",
		left: 0,
		position: "absolute",
		top: 0,
		width: "100%",
		background: `url(${BackgroundTransparentGrid})`,
		boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)"
	},
	mobileActionBar: {
		width: "100vw",
		height: `${mobileActionBarHeight}px`,
		display: "flex",
		textAlign: "center",
		[theme.breakpoints.up("sm")]: {
			display: "none"
		}
	},
	mobileActionButton: {
		backgroundColor: "#000000",
		color: colors.greyScale[10],
		"&:hover": {
			backgroundColor: "#444444",
			opacity: 0.8
		}
	},
	mobileActionIconBtn: {
		marginRight: theme.spacing(1)
	},
	isMobilePreviewActive: {
		height: "100%",
		display: props => !props.isMobilePreviewActive ? "none" : "block"
	},

	avatarContainer: {
		minWidth: theme.spacing(9),
		"&:focus": {
			outline: "none"
		}
	},
	avatarItem: {
		width: "56px",
		height: "56px"
	},
	avatarBadgeItem: {
		backgroundColor: colors.palette.confirm,
		padding: "4px",
		borderRadius: "50%",
		color: colors.greyScale[11]
	},
	alreadyRegisteredCaption: {
		padding: theme.spacing(0.5, 1)
	}
}))

export default useStyles
