const colors = {
	palette: {
		primary: "#FF4949",
		confirm: "#1AB956",
		error: "#D84910",
		tag1: "#5CC395",
		tag2: "#5BD1D7",
		tag3: "#F1C34E",
		link: "#007CBA",
		background: "#F8F9FA"
	},
	greyScale: {
		1: "#212429",
		2: "#DDE2E5",
		3: "#ACB5BD",
		4: "#495057",
		5: "#666666",
		6: "#969696",
		7: "#ABABAB",
		8: "#BFBFBF",
		9: "#D0D0D0",
		10: "#E1E1E1",
		11: "#F0F0F0",
		12: "#FFFFFF"
	}
}

export default colors
