import React, { ReactNode, useState, useEffect } from "react"
import {
	ThemeProvider,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isMobile } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/ActionDialog/styles"

type ActionDialogProps = {
	title: string
	children: ReactNode
	onSave: () => Promise<void>
	onClose: () => void

	closable?: boolean
	loading?: boolean
	fullWidth?: boolean
}

type ActionDialogType = {
	open: (props: ActionDialogProps) => void
}

const ActionDialog: ActionDialogType & React.FC<ActionDialogProps> = (props) => {
	const {
		title,
		closable,
		children,
		loading,
		fullWidth,
		onClose,
		onSave
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(!closable)

	const handleClose = () => {
		onClose()

		setOpened(false)
	}

	const handleSave = async () => {
		await onSave()
	}

	useDidMount(() => {
		if (isMobile) {
			handleBackButton({
				onBackButtonPress: handleClose
			}
			)
		}
	})

	useEffect(() => {
		setOpened(!closable)
	}, [closable])

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
				fullWidth={fullWidth}
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					{title}
				</DialogTitle>
				<DialogContent>
					{children}
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					<Button onClick={handleClose} className={classes.cancelButton}>
						Cancelar
					</Button>

					<Button
						onClick={handleSave}
						variant="contained"
						color="primary"
						endIcon={loading && <CircularProgress size={20} />}
						disabled={loading}
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

ActionDialog.open = (props: ActionDialogProps) => {
	renderComponent(
		"info-dialog",
		<ActionDialog
			{...props}
		/>
	)
}

export default ActionDialog
