import React, { useState } from "react"
import {
	Grid,
	Button,
	Typography,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent
} from "@material-ui/core"

import {
	CodeOutlined as CodeIcon,
	CloseOutlined as CloseIcon
} from "@material-ui/icons"

import AnonymousSignUp from "@/pages/Auth/components/AnonymousSignUp"
import useStyles from "@/components/Widgets/HeaderButton/styles"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import logoImg from "@/assets/images/logos/letalk-logo.svg"
import PreviewCode from "@/components/PreviewCode"

type PropsType = {
	widgetId: number
	title?: string
}

export type DialogTitleProps = {
	onClose: () => void
}

const WidgetHeaderButton = (props: PropsType) => {
	const { widgetId, title } = props

	const [openViewCodeDialog, setOpenViewCodeDialog] = useState<boolean>(false)
	const [openSignUpDialog, setOpenSignUpDialog] = useState<boolean>(false)

	const classes = useStyles()

	const closeViewCodeDialog = () => {
		setOpenViewCodeDialog(false)
	}

	const handleClickOpenSignUp = () => {
		setOpenSignUpDialog(true)
	}
	const handleCloseSignUp = () => {
		setOpenSignUpDialog(false)
	}

	const handleViewCode = async () => {
		try {
			const { data } = await ApiService.get("/customer")

			if (data.type === "anonymous") {
				handleClickOpenSignUp()
			} else {
				setOpenViewCodeDialog(true)
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const SignUpDialogTitle = (props: DialogTitleProps) => {
		const { onClose } = props
		return (
			<DialogTitle disableTypography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		)
	}

	return (
		<>
			<Grid
				container
				justify="space-between"
				className={classes.topHeaderWrapper}
			>
				<Grid item xs={12} sm={7}>
					<Grid
						container
						alignContent="center"
						alignItems="center"
						className={classes.topHeaderTitleContent}
					>
						<Grid item className={classes.topHeaderImage}>
							<img src={logoImg} alt="Logo" />
						</Grid>

						{
							title &&
							<Grid item xs={6} className={classes.topHeaderTitle}>
								<Typography>{title}</Typography>
							</Grid>
						}
					</Grid>
				</Grid>

				<Grid item xs sm className={classes.topHeaderButtonContainer}>
					<Button
						variant="contained"
						color="secondary"
						endIcon={<CodeIcon />}
						className={classes.topHeaderButton}
						onClick={handleViewCode}
					>
						COLOCAR EM MEU SITE
					</Button>
				</Grid>
			</Grid>

			{
				widgetId &&
				<PreviewCode
					isClosable={!openViewCodeDialog}
					widgetId={widgetId}
					onClose={closeViewCodeDialog}
				/>
			}

			<Dialog
				onClose={handleCloseSignUp}
				aria-labelledby="signup-dialog"
				open={openSignUpDialog}>
				{SignUpDialogTitle({ onClose: handleCloseSignUp })}
				<DialogContent className={classes.signUpContent}>
					<AnonymousSignUp />
				</DialogContent>
			</Dialog>

		</>
	)
}

export default WidgetHeaderButton
