import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, CircularProgress, Container, Grid, InputLabel, TextField } from "@material-ui/core"

import { Divider } from "@/components"

import ApiService from "@/services/Api"
import { ResetData } from "@/Protocols/AuthProtocol"
import useValidation from "@/hooks/useValidation"
import useQuery from "@/hooks/useQuery"

import useAuthStyles from "@/pages/Auth/styles"

import logoImg from "@/assets/images/logos/letalk-logo.svg"

const ResetRecovery = () => {
	const [resetData, setResetData] = useState<ResetData>({} as ResetData)
	const [loadingRecovery, setLoadingRecovery] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const query = useQuery()
	const history = useHistory()
	const authClasses = useAuthStyles()

	const passwordToken = query.get("token")

	const handleChangeResetData = (key: keyof ResetData, value: string) => {
		setResetData(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setLoadingRecovery(true)

		try {
			await ApiService.put("/auth/password/recovery", {
				...resetData,
				token: passwordToken
			})

			history.push("/signin")
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingRecovery(false)
	}

	return (
		<Container
			maxWidth={"xs"} className={authClasses.authContainer}
		>
			<Grid
				container
				justify="center"
				component="form"
				onSubmit={handleSubmit}
			>
				<Grid item className={authClasses.authItem}>
					<img
						src={logoImg}
						alt="Logo"
						className={authClasses.authLogoImage}
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid item className={authClasses.authItem}>
					<InputLabel className={authClasses.authLabel}>Nova senha</InputLabel>
					<Divider orientation="horizontal" size={1} />
					<TextField
						variant="outlined"
						value={resetData.password}
						placeholder="Digite sua nova senha aqui..."
						onChange={({ target }) => handleChangeResetData("password",
							target.value.toLocaleLowerCase())}
						helperText={validation.password}
						error={!!validation.password}
						fullWidth
						color="secondary"
						size="medium"
						type="password"
						autoFocus
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Grid item className={authClasses.authItem}>
					<Button
						variant="contained"
						color="secondary"
						disabled={loadingRecovery}
						endIcon={loadingRecovery && (
							<CircularProgress size={20} color="inherit" />
						)}
						type="submit"
						className={authClasses.authCenter}
						fullWidth
					>
						Alterar senha
					</Button>
				</Grid>

			</Grid>
		</Container>
	)
}

export default ResetRecovery
