import React, { useState } from "react"
import { useParams } from "react-router-dom"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import useDidMount from "@/hooks/useDidMount"
import useQuery from "@/hooks/useQuery"

import { Loading } from "@/components"

import ChatWhatsapp from "@/pages/WidgetChat/ChatWhatsapp"
import ProofsOfConcept from "@/pages/WidgetChat/ProofsOfConcept"
import EditorNotEncountered from "@/pages/WidgetChat/EditorNotEncountered"
import { ObjectLiteralReactElementProtocol } from "@/Protocols/GeneralProtocol"

type WidgetDataType = {
	widgetData?: {
		widgetId: number
		widgetType: string
		showAlreadyRegistered?: boolean
	}
}

type SelectWidgetType = {
	id: number
	type: string
}

const WidgetChat: React.FC<WidgetDataType> = (props) => {
	const { widgetData } = props

	const [selectWidget, setSelectWidget] = useState<SelectWidgetType>({} as SelectWidgetType)
	const { widgetId } = useParams<{ widgetId: string }>()
	const query = useQuery()

	const customer_site = query.get("customer_site")

	const getWidget = async () => {
		try {
			const { data } = await ApiService.get(`/widget/${widgetId}`)
			const { widget } = data

			setSelectWidget({
				id: widget.id,
				type: widget.type
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	useDidMount(() => {
		if (!widgetData?.widgetId) {
			getWidget()
		} else {
			setSelectWidget({
				id: widgetData.widgetId,
				type: widgetData.widgetType
			})
		}
	})

	if (selectWidget?.id) {
		const widgetEditorOptions: ObjectLiteralReactElementProtocol = {
			chatWhatsApp: <ChatWhatsapp
				widgetId={selectWidget.id}
				customerSite={customer_site}
				showAlreadyRegistered={widgetData?.showAlreadyRegistered}
			/>,
			proofConcept: <ProofsOfConcept
				widgetId={selectWidget.id}
				customerSite={customer_site}
			/>,
			default: <EditorNotEncountered />
		}

		return widgetEditorOptions[selectWidget.type] ?? widgetEditorOptions.default
	} else {
		return (
			<Loading loading />
		)
	}
}

export default WidgetChat
