import { useState } from "react"

import { Notification } from "@/components"

type ErrorType = {
	message: string
	response: {
		data: {
			messages: Array<string>
		}
	}
}

type ValidationType = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any
}

/**
	*
	* @param {error} AxiosError
	*/
const parseError = (error: ErrorType) => {
	// eslint-disable-next-line
	const validationMessages = error && error.response && error.response.data && error.response.data.messages
	const errorMessage = error && error.message

	return {
		validationMessages,
		errorMessage
	}
}

const useValidation = () => {
	const [validation, setValidation] = useState<ValidationType>({})

	// eslint-disable-next-line
	const triggerUndeterminedValidation = (error: ErrorType) => {
		Notification.error({ message: "Algo deu errado." })
		if (process.env.NODE_ENV === "development") {
			// eslint-disable-next-line
			console.log({ error })
		}
	}

	/**
		*
		* @param {error} AxiosError
		*/
	const triggerValidation = (error: ErrorType) => {
		const data = parseError(error)

		if (!data.validationMessages) {
			triggerUndeterminedValidation(error)
		} else {
			setValidation((lastValidation) => ({
				...lastValidation,
				...data.validationMessages
			}))
		}
	}

	const clearValidation = (key: string) => {
		if (key in validation) {
			setValidation((lastValidation) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedData: any = { ...lastValidation }

				delete updatedData[key]

				return updatedData
			})
		}
	}

	return {
		triggerUndeterminedValidation,
		triggerValidation,
		clearValidation,
		validation
	}
}

export default useValidation
