import React from "react"
import { useParams } from "react-router-dom"

import useDidMount from "@/hooks/useDidMount"
import useQuery from "@/hooks/useQuery"

import { Loading } from "@/components"

import AuthService from "@/services/Auth"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

type callbackOptionsType = {
	[key: string]: string
}

const Integration = () => {
	const { loginToken, widgetId } = useParams<{ loginToken: string, widgetId: string }>()
	const query = useQuery()

	const customer_site = query.get("customer_site")
	const widgetType = query.get("widget_type") as string

	const handleLogin = async () => {
		let authToken = null
		try {
			const response = await ApiService.get("/integration/login", {
				params: {
					loginToken
				}
			})

			authToken = response?.data?.authToken
		} catch (error) {
			ErrorHandlerService.handle(error)
		}

		const callbackOptions: callbackOptionsType = {
			chatWhatsApp: `/widget-editor/${widgetId}?customer_site=${customer_site}`,
			default: "/"
		}

		const callbackHref = callbackOptions[widgetType] ?? callbackOptions.default

		AuthService.login(authToken, callbackHref)
	}

	useDidMount(() => {
		handleLogin()
	})

	return (
		<Loading loading />
	)
}

export default Integration
