import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: theme.spacing(1)
	},
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	dialogActions: {
		marginTop: theme.spacing(2)
	},
	cancelButton: {
		color: colors.greyScale[4]
	}
}))

export default useStyles
