import React, { useState, useEffect } from "react"
import {
	Grid,
	Button,
	Container,
	Typography,
	Dialog,
	Box,
	Fade,
	IconButton,
	ButtonBase
} from "@material-ui/core"

import {
	Close as CloseIcon
} from "@material-ui/icons"

import copy from "copy-to-clipboard"

import { Divider, Notification, Loading } from "@/components"
import ApiService from "@/services/Api"

import useStyles from "@/components/PreviewCode/styles"

import GetCodeSkeleton from "@/skeletons/MyWidgets/GetCode"

import rocketIllustrationImg from "@/assets/images/illustrations/rocketIllustrations.svg"

import wordpressLogoImg from "@/assets/images/logos/wordpress-logo.svg"
import wixLogoImg from "@/assets/images/logos/wix-logo.svg"

type PropsType = {
	widgetId: number
	isClosable: boolean
	onClose: () => void
}

type WidgetCodeType = {
	codeScript: string
	codeDiv: string
}

const PreviewCode = (props: PropsType) => {
	const { widgetId, isClosable, onClose } = props

	const [openViewCodeDialog, setOpenViewCodeDialog] = useState<boolean>(false)

	const [loadingGetCodeData, setLoadingGetCodeData] = useState<boolean>(true)
	const [codeData, setCodeData] = useState<WidgetCodeType>({} as WidgetCodeType)

	const [showCopyBox, setShowCopyBox] = useState<boolean>(false)

	const classes = useStyles()

	const closeViewCodeDialog = () => {
		setOpenViewCodeDialog(false)
		onClose()
	}

	const getPreviewCode = async () => {
		setOpenViewCodeDialog(true)
		setLoadingGetCodeData(true)

		try {
			const { data } = await ApiService.get(`/widgetCodePreview/${widgetId}`)

			setCodeData(data)
		} catch (error) {
			Notification.error({
				message: "Falhar ao gerar o código..."
			})
		}

		setLoadingGetCodeData(false)
	}

	const handleCopyWidgetCode = () => {
		const widgetCode = `${codeData.codeScript} ${codeData.codeDiv}`

		copy(widgetCode)

		Notification.success({
			message: "Link copiado com sucesso!"
		})
	}

	const onCodeEnter = () => {
		setShowCopyBox(true)
	}

	const onCodeLeave = () => {
		setShowCopyBox(false)
	}

	useEffect(() => {
		if (!isClosable) {
			getPreviewCode()
		}
		return () => {
			setOpenViewCodeDialog(false)
		}
		// eslint-disable-next-line
	}, [isClosable])

	return (

		<Dialog
			open={openViewCodeDialog}
			onClose={closeViewCodeDialog}
		>
			<Fade in={openViewCodeDialog}>
				<Grid container>
					<Grid item className={classes.headerDialog}>
						<IconButton onClick={closeViewCodeDialog} className={classes.closeIcon}>
							<CloseIcon />
						</IconButton>

						<Grid container justify="center">
							<Grid item className={classes.imageHeaderWrapper}>
								<img src={rocketIllustrationImg} alt="Widget Code" />
							</Grid>

							<Divider size={3} orientation="horizontal" />

							<Grid item>
								<Typography className={classes.titleHeaderDialog} variant="body1">
									Parabéns, agora é só colocar o código em seu site
								</Typography>
							</Grid>

						</Grid>
					</Grid>

					<Grid item className={classes.bodyDialog}>
						<Typography align="left">Copie e cole esse código na tag header do seu site.</Typography>

						<Divider size={3} orientation="horizontal" />
						<Loading
							loading={loadingGetCodeData}
							customLoadingElement={<GetCodeSkeleton />}
						>
							<Container
								onMouseEnter={onCodeEnter}
								onMouseLeave={onCodeLeave}
								onClick={handleCopyWidgetCode}
								className={classes.wrapperCode}>
								<Typography variant="body1" className={classes.codeContent}>
									{codeData.codeScript}
								</Typography>

								<Typography variant="body1" className={classes.codeContent}>
									{codeData.codeDiv}
								</Typography>

								<Fade in={showCopyBox}>
									<Box component="span" className={classes.copyBox}>
										Clique para copiar
									</Box>
								</Fade>
							</Container>
						</Loading>

						<Divider size={4} orientation="horizontal" />

						<Button
							onClick={closeViewCodeDialog}
							variant="contained"
							color="secondary"
							className={classes.buttonDialogCode}
						>
							Já instalei o código em meu site
						</Button>

						<Divider size={4} orientation="horizontal" />

						<Grid
							container
							direction="column"
							alignItems="flex-start"
						>
							<Typography
								variant="body1"
							>
								Utiliza alguma dessas ferramentas? Confira o passo a passo!
							</Typography>

							<Divider size={2} orientation="horizontal" />
						</Grid>

						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								container
								alignItems="center"
								justify="center"
								xs={4}
							>
								<a
									href="https://www.youtube.com/watch?v=KB3cD3h8t88"
									target="_blank"
									rel="noopener noreferrer"
									style={{ width: "100%" }}
								>
									<ButtonBase
										className={classes.platformHelpButton}
									>
										<img
											src={wordpressLogoImg}
											alt="Wordpress Logo"
										/>
									</ButtonBase>
								</a>
							</Grid>

							<Grid
								item
								container
								alignItems="center"
								justify="center"
								xs={4}
							>
								<a
									href="https://www.youtube.com/watch?v=AAEfoAPjFL4"
									target="_blank"
									rel="noopener noreferrer"
									style={{ width: "100%" }}
								>
									<ButtonBase
										className={classes.platformHelpButton}
									>
										<img
											src={wixLogoImg}
											alt="Wix Logo"
										/>
									</ButtonBase>
								</a>
							</Grid>

							<Grid
								item
								container
								alignItems="center"
								justify="center"
								xs={4}
							>
								<a
									href="https://www.youtube.com/watch?v=T7prT3AxubI"
									target="_blank"
									rel="noopener noreferrer"
									style={{ textDecoration: "none", width: "100%" }}
								>
									<ButtonBase
										className={classes.platformHelpButton}
									>
										<Typography
											variant="body1"
											className={classes.otherPlatformsText}
										>
											Outras plataformas
										</Typography>
									</ButtonBase>
								</a>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</Fade>
		</Dialog>

	)
}

export default PreviewCode
