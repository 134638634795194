import React, { useState } from "react"
import { Route, Switch, Redirect, useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"

import { Loading } from "@/components"

import AuthService from "@/services/Auth"
import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"

import useDidMount from "@/hooks/useDidMount"

import StepOne from "@/pages/Auth/SignUp/Step-1"
import StepTwo from "@/pages/Auth/SignUp/Step-2"

import { WidgetDataResponseType } from "@/Protocols/WidgetProtocol"

const Signup: React.FC = () => {
	const [loading, setLoading] = useState(true)

	const history = useHistory()

	const createAnonymousCustomerIfNeeded = async () => {
		if (!AuthService.isLoggedIn) {
			const { data } = await ApiService.post("/customer/anonymous")

			const authToken = data?.authToken

			AuthService.login(authToken, "/signup/step-one")
		} else {
			const widget = StorageService.get("customer-widget-data") as WidgetDataResponseType
			if (widget) {
				history.push("/signup/step-two")
			}
			setLoading(false)
		}
	}

	useDidMount(() => {
		createAnonymousCustomerIfNeeded()
	})

	return (
		<>
			<Redirect exact path="/signup" to="/signup/step-one" />

			<Loading loading={loading}>
				<Grid container direction="column">
					<Grid item style={{ flex: 1 }}>
						<Grid container direction="column" style={{ height: "100%" }}>
							<Switch>
								<Route exact path="/signup/step-one" component={StepOne} />
								<Route exact path="/signup/step-two" component={StepTwo} />
							</Switch>
						</Grid>
					</Grid>
				</Grid>
			</Loading>
		</>
	)
}

export default Signup
