import StorageService from "@/services/Storage"

import authConfig from "@/config/auth"

class AuthService {
	get authTokenKey (): string {
		return authConfig.authTokenKey
	}

	get authToken (): string | null {
		const authToken = StorageService.get<string>(this.authTokenKey)

		return authToken
	}

	get isLoggedIn (): boolean {
		const authToken = this.authToken

		return !!authToken
	}

	login (authToken: string, callbackHref?: string): void {
		StorageService.set(this.authTokenKey, authToken)

		if (callbackHref) {
			window.location.replace(callbackHref)
		}
	}

	logout (): void {
		StorageService.delete(this.authTokenKey)
	}
}

export default new AuthService()
