import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	topHeaderContainer: {
		height: "auto"
	},
	previewWidgetContainer: {
		height: "calc(100vh - 60px)",
		padding: theme.spacing(2, 1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: colors.greyScale[5]
	},
	signUpContent: {
		padding: theme.spacing(3, 4)
	}
}))

export default useStyles
